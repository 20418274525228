<template>
  <div class="single-page">
    <section v-if="singlePages">
      <b-container class="pt-5 pb-5">
        <b-row>
          <b-col
            v-for="page in singlePages"
            :key="page.id"
            col
            md="8"
            class=" mb-5"
          >
            <b-img
              fluid-grow
              :src="
                `${path.storage_files + page.cover_image.formats.medium.url}`
              "
              :alt="`${page.cover_image.name}`"
              class="z-depth-3"
            ></b-img>

            <h2 class="display-5 text-center super-strong mt-4 mb-4">
              {{ page.title }}
            </h2>

            <section class="markdown-content">
              <vue-simple-markdown
                :source="`${page.paragraph}`"
              ></vue-simple-markdown>
            </section>
          </b-col>
          <b-col cols="4">
            <Sidebar twitterUser="IME_SRE" twitterLimit="1"></Sidebar>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section v-else>
      <h1 class="display-1">
        Error 404, La pagina que buscas no esta disponible
      </h1>
    </section>
  </div>
</template>

<script>
import Sidebar from "@/components/layout/Sidebar.vue";

export default {
  name: "SinglePages",
  components: {
    Sidebar,
  },

  props: {
    cfgTitle: String,
  },

  data() {
    return {
      singlePages: [],
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      markdown: "# Hello World",
    };
  },

  mounted() {
    document.title = this.cfgTitle
      ? this.cfgTitle
      : "Instituto de los Mexicanos en el Exterior";
  },

  /*
   * Ajuste segun categoria
   * Hereda de Categoria Padre
   */
  async created() {
    await fetch(
      this.path.endpoint +
        "/single-pages/?canonical=" +
        this.$route.params.canonical // <- Ajuste segun Categoria
    )
      .then((response) => response.json())
      .then((data) => {
        this.singlePages = data;
        //console.log(data);
        console.log(data);
      })
      .catch((err) => {
        console.error(err);
      });
  },
  /* End Ajuste */

  computed: {
    markdownToHtml() {
      return marked(this.markdown);
    },
  },

  methods: {},
};
</script>
