<template>
  <div class="component-latest-global-post">
    <h3 class="mt-4">Lo último en el IME</h3>
    <hr class="line sm" />
    <aside
      class="mb-4 "
      col
      lg="4"
      md="6"
      sm="12"
      v-for="post in latestPosts"
      :key="post.id"
    >
      <b-row>
        <b-col col lg="4" md="4" sm="12">
          <b-img
            fluid-grow
            :src="
              `${
                post.image_home_local
                  ? path.storage_files +
                    post.image_home_local.formats.thumbnail.url
                  : post.image_home
              }`
            "
            class="z-depth-2"
          ></b-img>
        </b-col>
        <b-col col lg="8" md="8" sm="12">
          <h6 class="latest-title mt-0 mb-1">
            <b-link
              :to="`/${post.category_id.slug}/articulo/${post.canonical}`"
            >
              <!-- Ajustar para Slug -->
              {{ post.title }}
            </b-link>
          </h6>
          <div class="block mt-1 mb-1">
            <b-link :to="`/${post.category_id.slug}`">
              <b-badge class="rojo light ">
                <i class="fas fa-tag"></i>
                {{ post.category_id.name }} </b-badge
              >&nbsp;
            </b-link>
            <b-badge class="verde light">
              <i class="fas fa-calendar-alt"></i>
              {{ post.published_at | moment("DD/MM/YY") }}
            </b-badge>
          </div>
        </b-col>
      </b-row>
    </aside>
  </div>
</template>

<script>
import DataBlogs from "@/services/data-blogs";

export default {
  name: "LatestGlobaPost",

  components: {},

  data() {
    return {
      latestPosts: [],
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
    };
  },

  mounted() {
    this.retrieveLatestPosts();
  },

  methods: {
    retrieveLatestPosts() {
      DataBlogs.getAllByList()
        .then((response) => {
          this.latestPosts = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
