<template>
  <div class="component-sidebar">

    <aside class="mb-5 pb-3 component-sidebar">
      <b-img
        fluid-grow
        :src="`${imageCover }`" 
        :alt="`${imageTitle}`" 
        class="z-depth-3 box-10" />
      <p class="mt-2 text-center">{{ imageTitle }}</p>
    </aside>


    <aside v-if=" videoView === 'true' && videoPosition === 'sidebar' " class="side-block mb-5 pb-3">
      <h3><i class="fab fa-youtube"></i>  &nbsp;Multimedia</h3>
      <hr class="line sm">
      <div 
        class="lime darken-3 white-text box-10 p-3 pb-1 z-depth-3"
        :class="`youtube-${videoId}`"
      >
        <p class="lead mb-2 strong text-shadow">{{ videoTitle }}</p>
        <b-embed
          type="iframe"
          aspect="16by9"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          :src="`https://www.youtube-nocookie.com/embed/${videoId}?rel=0`"
          allowfullscreen
          class="video-sidebar box-10 z-depth-2"
        ></b-embed>
        <p class="text-center mt-1"> {{ videoDescription }}</p>
      </div>
    </aside>
    <aside v-else-if="videoView === null || videoView === 'undefined'" class="hidden"></aside>


    <aside v-if="socialMedia.twitter" class="side-block mb-5 pb-3">
      <h3 class="">Twitter @{{ socialMedia.twitter ? socialMedia.twitter : 'IME_SRE' }} </h3>
      <hr class="line sm">
      <Timeline
        :id="`${ socialMedia.twitter ? socialMedia.twitter : 'IME_SRE' }`" 
        sourceType="profile" 
        :options="{ tweetLimit: this.twitterLimit ? this.twitterLimit : 2 }" 
      ></Timeline>
    </aside>


    <aside class="side-block mb-5 pb-3" v-if="socialMedia === true">
      <h3 class="">Siguenos</h3>
      <hr class="line sm">

      <b-button variant="light" class="m-1 blue darken-2 white-text" v-if="socialMedia.facebook">
        <i class="fa-brands fa-facebook"></i>&nbsp;
        fb.me/{{ socialMedia.facebook }}
      </b-button>
      <b-button variant="light" class="m-1 light-blue lighten-1 white-text" v-if="socialMedia.twitter">
        <i class="fa-brands fa-twitter"></i>&nbsp;
        @{{ socialMedia.twitter }}
      </b-button>
      <b-button variant="light" class="m-1 red darken-2 white-text" v-if="socialMedia.youtube">
        <i class="fa-brands fa-youtube"></i>&nbsp;
        {{ socialMedia.youtube }}
      </b-button>
      <b-button variant="light" class="m-1 pink accent-2 white-text" v-if="socialMedia.instagram">
        <i class="fa-brands fa-instagram"></i>&nbsp;
        {{ socialMedia.instagram }}
      </b-button>
      <b-button variant="light" class="m-1 teal lighten-1 white-text " v-if="socialMedia.tiktok">
        <i class="fa-brands fa-tiktok"></i>&nbsp;
        {{ socialMedia.tiktok }}
      </b-button>
      <b-button variant="light" class="m-1 grey darken-3 white-text" v-if="socialMedia.phone">
        <i class="fa-solid fa-phone"></i>&nbsp;
        {{ socialMedia.phone }}
      </b-button>
      <b-button variant="light" class="m-1 blue-grey darken-1 white-text" v-if="socialMedia.email">
        <i class="fa-solid fa-envelope"></i>&nbsp;
        {{ socialMedia.email }}
      </b-button>
      <b-button variant="light" class="m-1 green accent-4 white-text" v-if="socialMedia.whatsapp">
        <i class="fa-brands fa-whatsapp"></i>&nbsp;
        {{ socialMedia.whatsapp }}
      </b-button>
      <p v-if="socialMedia.instructions">
        <vue-simple-markdown :source="`${socialMedia.instructions}`"></vue-simple-markdown>
      </p>
    </aside>




    <aside 
      v-if="this.$route.name === 'CategorySalud' || this.$route.name === 'PageSalud'" 
      class="side-block mb-5"
    >
      <SidebarExternalLinks></SidebarExternalLinks>
    </aside>


    <!--<div v-if="this.$route.name === 'UpcomingEvents' || this.$route.name === 'UpcomingEventsShow'" ></div>
    <div v-else>
      <EventsGlobal></EventsGlobal>
    </div>-->



    <aside class="side-block mb-5 pb-3">
      <LatestGlobalPost></LatestGlobalPost>
    </aside>


    <aside class="side-block mb-5 pb-3">
      <h3 class="">Twitter @{{ this.twitterUser ? this.twitterUser : 'IME_SRE' }} </h3>
      <hr class="line sm">
      <Timeline
        :id="`${ this.twitterUser ? this.twitterUser : 'IME_SRE' }`" 
        sourceType="profile" 
        :options="{ tweetLimit: this.twitterLimit ? this.twitterLimit : 2 }" 
      ></Timeline>
    </aside>


  </div>
</template>

<script>
import LatestGlobalPost from '@/components/Global/LatestGlobalPost.vue';
import EventsGlobal from '@/components/Global/EventsGlobal.vue';
import SidebarExternalLinks from '@/components/Custom/Layout/SidebarExternalLinks.vue';

import {Timeline} from 'vue-tweet-embed';

export default {
  name: 'Sidebar',
  components:{
    Timeline,
    LatestGlobalPost,
    EventsGlobal,
    SidebarExternalLinks,
  },
  props: {
    videoView: String,
    videoPosition: String,
    videoTitle: String,
    videoDescription: String,
    videoId: String,
    twitterUser: String,
    twitterLimit: String,
    socialMedia: Object,
    imageCover: String,
    imageTitle: String
  }
}
</script>
