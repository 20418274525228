<template>
  <section class="sidebar-external-links mb-1">
    <h3 class="mt-2">Estrategia de Salud para Familias Mexicanas en EU</h3>
    <hr class="line sm">
    <b-list-group flush class=" mb-4">
      <b-list-group-item href="https://semanabinacionalmexicousa.org/" class="strong">
          XXI Semana Binacional de Salud <span class="light">(Sitio Oficial)</span>
      </b-list-group-item>
      <b-list-group-item href="https://ventanillasdesaludums.org/" class="strong list-bottom-border">
          Ventanillas de Salud
      </b-list-group-item>
    </b-list-group>
  </section>
</template>

<script>
//import ExternalLinks from '@/components/Custom/Outstanding/ExternalLinks.vue';

export default {
  name: 'SidebarExternalLinks',
  components: {
  //  ExternalLinks,
  },

  data() {
    return {}
  },

  mounted() {
  },

  methods: {
  },


}
</script>
