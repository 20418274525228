<template>
  <div class="component-events-global mb-5">
    <h3 class="mt-4">Próximos Eventos</h3>
    <hr class="line sm">
    <b-list-group class="list-group-flush mb-4">
      <b-list-group-item
        v-for="event in upcomingEvents"
        :key="event.id"
      >
        <b-link :to="`/eventos/${event.id}`" class=" ">
          <span class="light blue-grey-text">{{event.date}}</span>
          <span class="strong blue-grey-text text-lighten-4"> / </span>
          <span class="strong blue-grey-text text-darken-2">{{ event.title }}</span>
        </b-link>
        <br />
        <small>
          <i class="fas fa-clock"></i> {{ event.start_time | moment('h:mm a') }} &nbsp;
          <i class="fas fa-stopwatch"></i> {{ event.end_time | moment('h:mm a') }} &nbsp;
          <b-link :to="`/eventos/${event.id}`" class="text-uppercase">
            <i class="fas fa-info-circle"></i> Información
          </b-link>
        </small>
      </b-list-group-item>
    </b-list-group>
    <b-button
      class="rojo white-text"
      to="/eventos/">
      <i class="fas fa-calendar-alt"></i> Ver calendario
    </b-button>
  </div>
</template>

<script>
import DataEvents from "@/services/data-events";

export default {
  name: 'EventsGlobal',

  components:{},

  data() {
    return {
      upcomingEvents: [],
    }
  },

  mounted() {
    this.retrieveEvents();
  },

  methods: {

    retrieveEvents() {
      DataEvents.getLandingSections()
      .then(response => {
          this.upcomingEvents = response.data;
          console.log(response.data);
      })
      .catch(e => {
          console.log(e);
      });
    },

  },


}
</script>
