import http from "@/services/http-common";

class DataEvents {

    getAll() {
        return http.get('/upcoming-events/?_sort=date:asc');
    }

    getLandingSections() {
        return http.get('/upcoming-events/?_sort=date:asc&_limit=5');
    }

    getEventsSix() {
        return http.get('/upcoming-events/?_sort=date:asc&_limit=6');
    }

    get(id) {
        return http.get(`/upcoming-events/${id}`);
    }

}

export default new DataEvents();
